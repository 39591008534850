import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { CustomerDto, CustomersVm } from 'src/lib/api';
import type { AppThunk } from 'src/store';

interface CustomersState {
  customers: Array<CustomerDto>;
  isLoaded: boolean;
}

const initialState: CustomersState = {
  customers: [],
  isLoaded: false
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    loading(state: CustomersState) {
      state.isLoaded = false;
    },
    getCustomers(state: CustomersState, action: PayloadAction<CustomerDto[]>) {
      state.customers = action.payload;
      state.isLoaded = true;
    }
  }
});
//------------------------------------------------------------------

export const reducer = slice.reducer;

//------------------------------------------------------------------

export const getCustomers = (): AppThunk => async (dispatch, getState) => {
  const { customers } = getState().customers;

  if (customers.length === 0) {
    dispatch(slice.actions.loading());
    const { data: { customers } = {} } = await axios.get<CustomersVm>(
      '/api/customers'
    );

    dispatch(slice.actions.getCustomers(customers));
  }
};

export default slice;
