import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { CustomerDto } from 'src/lib/api';
import { getCustomers } from 'src/slices/customers';
import { useDispatch, useSelector } from 'src/store';

interface ChangeCustomerMenuItemProps {
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    title: {
      color: 'textPrimary',
      marginBottom: theme.spacing(2)
    }
  })
);

const ChangeCustomerMenuItem: FC<ChangeCustomerMenuItemProps> = ({
  onClick
}) => {
  const { customers } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { setCustomer } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  const handleOpen = () => {
    onClick();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeCustomer = (event: any, customer: CustomerDto | null): void => {
    setCustomer(customer);
    enqueueSnackbar(`Current customer set to ${customer.id}`, {
      variant: 'success'
    });
    handleClose();
  };

  return (
    <div>
      <MenuItem onClick={handleOpen}>Change Customer View</MenuItem>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography variant="h3" className={classes.title}>
              Change Customer View
            </Typography>
            <Autocomplete
              id="combo-box-cusomters"
              options={customers}
              getOptionLabel={(option) => `${option.id} - ${option.name}`}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Customers" variant="outlined" />
              )}
              onChange={changeCustomer}
              // inputValue={inputValue}
              // onInputChange={(event, newInputValue) => {
              //   setInputValue(newInputValue);
              // }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ChangeCustomerMenuItem;
